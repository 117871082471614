import React, { Component } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ReactHtmlParser from "react-html-parser"
import PropTypes from "prop-types"
import Link  from "../components/delayed-gatsby-link";


class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fade: false,
      isTransitioning: false
    }

    this._itemClicked = this.itemClicked.bind(this);
  }

  itemClicked(event){
    window.dispatchEvent( new Event('animateOut') );
  }

  getRelated(){
    const post = this.props.data.wordpressPost
    var slug = post.slug
    var pluckArr = this.props.data.allWordpressPost.edges.slice(0);
    var arr = [];
    var maxItems = 2;
    if(pluckArr.length > maxItems){
      var max = pluckArr.length < maxItems ? arr.length : maxItems
      while (arr.length<max){
        var item = pluckArr.splice(Math.floor(Math.random()*pluckArr.length), 1)[0];
        if(item && item.node.slug !== slug){
          arr.push(item);
        }
      }
      return arr;
    }
    return;
  }

  postHeroImage () {
    const post = this.props.data.wordpressPost
    if(post.acf){
      return (
        <section className="PostHeroImage no-padding-top no-padding-bottom no-padding-left no-padding-right fader">
          <figure>
            <div className="img-wrap">
              <img src={post.acf.featured_image.source_url} alt={post.acf.featured_image.alt_text} />
            </div>
          </figure>
        </section>
      );
    } else {
      return;
    }
  }

  render() {
    const post = this.props.data.wordpressPost
    const that = this
    let moreItems = this.getRelated().map(function(p, index){
      return (
        <li rel={p.slug} key={index} className="PostPreview">
          <Link delay={1000} to={"/blog/"+p.node.slug} onClick={that._itemClicked}>
            <div className="img-wrap fader">
              <div className="inner">
                <div className="grimage" style={{backgroundImage: "url(" + p.node.acf.featured_image.source_url + ")", backgroundSize: "cover"}}></div>
              </div>
            </div>
            <div className="innerlay">
              <div className="text-wrap fader">
                <time className="eyebrow">{p.node.date}</time>
                <h2 className="eyebrow-before">{p.node.title}</h2>
              </div>
            </div>
          </Link>
        </li>
      )
    });

    return (
      <div className="post ColorKey" data-bgcolor="#ffffff">
        <Helmet title={post.title+' - illcreative'}> { ReactHtmlParser(post.yoast_head.replace(new RegExp('http://localhost', 'g'),'https://www.illcreative.com'))}</Helmet>
        <div className="main-content">
          <article className="main-wrap">
              <section className="TopCopy">
                <h1 className="eyebrow-before fader">{post.title}</h1>
                <div className="fader" dangerouslySetInnerHTML={{__html: post.excerpt}}></div>
              </section>
              {this.postHeroImage()}
              <section className="PostContent">
                <div className="Wysiwyg" dangerouslySetInnerHTML={{__html: post.content}}></div>  
              </section>

              <section className="PostGrid no-padding-top no-padding-bottom">
                <div className="MorePosts fader">
                  <h3 className="down-arrow">Read More</h3>
                </div>
                <ul>
                  { moreItems }
                </ul>
              </section>
          </article>
        </div>
      </div>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}
 
export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      slug
      excerpt
      content
      date(formatString: "MMM DD, YYYY")
      yoast_head
      acf {
        featured_image {
          source_url
          alt_text
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          slug
          date(formatString: "MMM DD, YYYY")
          acf {
            featured_image {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`